.containerHome {
  display: flex;
  padding: 10px;
}

.sideBarTags {
  display: flex;
  margin: 20px;
  padding-bottom: 30px;
  background-color: gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.containerRight {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  /* justify-content: center; */
}

.containerRightContent {
  align-items: center;
}

.containerLeft {
  display: flex;
  width: 80%;
  /* background-color: purple; */
}

.containerBlogSection {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 80%;
}

.containerLoadMoreSection {
  padding: 10px;
  flex-direction: row;
}
.containerSearch {
  display: flex;
  padding: 10px;
}
