.containerEditBlog {
  display: flex;
  /* background-color: green; */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.containerAlert {
  display: flex;
  justify-content: center;
  padding: 10px;
  /* height: 100px; */
  width: 100%;
}
.containerAlertContent {
  width: 700px;
}
.containerEditBlogMainWrapper {
  display: flex;
  flex-direction: column;
  /* background-color: gray; */
  width: 80%;
}

.containerEditBlogTitle {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
}

.containerEditBlogFormMain {
  /* flex-direction: column; */
  padding-left: 10px;
}

.containerEditBlogInputText {
  display: flex;
  width: 100%;
}

.containerEditBlogTrendingTitle {
  display: flex;
  padding: 10px;
  font-family: Roboto;
}

.containerEditBlogRadioOptions {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  /* justify-content: center; */
}

.containerEditBlogSelectCategory {
  display: flex;
  padding: 10px;
}

.containerEditBlogTextArea {
  /* display: flex; */
  padding: 10px;
  /* width: 100%; */
  /* background-color: yellow; */
}

.containerEditBlogUploadFile {
  display: flex;
  padding: 10px;
}

.containerEditBlogSubmitButton {
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
}

.labelRadio {
  font-size: 14px;
  padding-left: 10px;
}

.radio {
  font-size: 20px;
  padding-left: 10px;
}

.selectOption {
  padding: 10px;
  font-size: 14px;
}

.containerx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.containerFormMain {
  display: flex;
  width: 100%;
  background-color: red;
  padding: 10px;
}
.containerTitle {
  top: 20px;
  margin-top: 50px;
  padding: 10px;
  padding-bottom: 25px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
}

.containerRadioOption {
  display: flex;
  flex-direction: row;
}

.editBlogInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 300px;
  max-width: 100%;
}

.textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 600px;
  max-width: 100%;
}
trendingLabel {
  padding-left: 10px;
}

.button_primary1 {
  border: none;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #ff9933;
  color: white;
  height: 50px;
  width: 20%;
  padding: 10px;
  font-size: 16px;
}
