.containerDetail {
  display: flex;
  /* padding: 10px; */
  width: 100%;
}

.containerDetailLeft {
  display: flex;
  width: 80%;
  background-color: purple;
  /* align-items: center; */
  align-content: center;
  justify-content: center;
}

.containerDetailRight {
  /* display: flex; */
  flex-direction: column;
  width: 20%;
  align-items: center;
  /* justify-content: center; */
}

.containerDetailLeftContent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: center; */
  width: 80%;
  background-color: yellow;
}

.containerDetailImg {
  /* width: 100%; */
  /* height: 400px; */
  /* object-fit: cover; */
  border-radius: 7px;
}

.containerDetailImgContent {
  display: flex;
  padding: 10px;
}

.containerDetailAuthorInfoSection {
  display: flex;
  flex-direction: row;
  /* padding-left: 10px; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* padding: 10px; */
  /* align-items: center; */
  /* align-content: center; */
}

.containerDetailAuthorInfoImage {
  display: inline;
  /* width: 80px;
  height: 80px; */
  /* width: 60px; */
  /* height: 60px; */
  /* border-radius: 20%; */
}

.containerDetailAuthorNameAndDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* align-content: center; */
  /* justify-content: center; */
}

.containerDetailLikeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.containerDetailAuthorName {
  font-size: 14px;
  color: #808080;
  display: flex;
  padding-left: 10px;
}
.containerDetailPostDate {
  font-family: Roboto;
  font-weight: lighter;
  font-size: 12px;
  display: flex;
  padding-left: 10px;
}

.containerDetailLikeSection {
  display: flex;
  color: '#757575';
}

.containerDetailBlogTitle {
  display: flex;
  padding-left: 10px;
  /* padding: 10px; */
  font-family: 'sohne, "Helvetica Neue", Helvetica, Arial, sans-serif';
  font-size: 24px;
  font-weight: bold;
}

.containerDetailBlogContent {
  display: flex;
  padding: 10px;
  font-family: 'Roboto';
  font-size: 18px;
}
