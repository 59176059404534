.containerRelatedBlog {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  align-items: flex-start;
}

.relatedBlogTitle {
  display: flex;
  /* padding: 10px; */
  font-family: Roboto;
  font-size: 20px;
  border-bottom: 1px solid black;
}

.relatedBlogCardContent {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
}
