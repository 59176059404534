.navbar {
  background-color: white;
  box-shadow: 0 8px 6px -6px #999;
}

.brand,
.list_item {
  color: #404040 !important;
  padding: 8px;
  border-bottom: none;
}

.list_item:hover {
  color: tomato !important;
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
  color: blue;
  cursor: pointer;
}

/* custom-navbar.css */

/* .navbar-nav .nav-link:active {
  border-bottom: none !important;
} */

.btn-container {
  height: 40px;
  display: flex;
  align-items: center;
}
.btn {
  background: tomato !important;
  border: none;
  border-radius: 10px;
}

.my-container {
  padding-top: 20px;
}

.my-col {
  padding: 4px;
}

.my-row {
  margin-bottom: 40px;
  padding-bottom: 10px;
}

.my-card-link {
  color: blue;
  font-size: 18px;
}
.my-image-container {
  width: 100%; /* Adjust the container width as needed */
  margin: 0 auto; /* Center the container horizontally */
}

.my-card {
  padding-top: 10px;
}

.userinfo-signupbutton {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* align-content: center; */
  /* justify-content: center; */
}

@media (max-width: 575px) {
  .no-left-padding {
    padding-left: 0 !important;
  }
}

.custom-alert {
  font-size: 14px;
  padding: 10px;
}

.grayed-out-button {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}
