.containerAddBlog {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.containerMain {
  display: flex;
  flex-direction: column;
  width: 50%;
}

h2 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 30px;
  color: #808080;
}

.textinput {
  /* display: flex; */
  width: 80%;
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px #ccc;
  height: 50px;
  padding: 10px;
  font-size: 16px;
}

.textarea {
  display: flex;
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
  height: 100px;
  width: 400px;

  font-size: 16px;
}

.label {
  margin-left: 20px;
  font-family: 'Roboto';
  color: #000000;
}

.form-input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  margin-left: 20px;
}
