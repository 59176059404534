.blogRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
}

.post {
  width: 100%;
  margin: 0px 25px 40px 25px;
  flex-direction: column;
}

.postImg {
  width: 385px;
  height: 280px;
  object-fit: cover;
  border-radius: 7px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.postCat {
  font-family: 'Varela Round', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitle {
  font-family: 'Josefin Sans', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
  cursor: pointer;
}

.blogSectionAuthorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.postAuthor {
  font-family: 'Josefin Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  cursor: pointer;
}

.postDate {
  font-family: 'Lora', serif;
  font-size: 12px;
  margin-left: 10px;
  color: #999999;
}

.buttonReadMore {
  border: none;
  background-color: #4caf50; /* Green */
  color: white;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.postDesc {
  font-family: 'Varela Round', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tag {
  display: flex;
  background-color: #ffb266;
  border-radius: 4px;
  padding: 5px 10px;
  height: 35px;
}

.blogSectionImage {
  display: flex;
  padding: 10px;
}

.blogSectionContent {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
}
