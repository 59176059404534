.box {
  /* display: flex; */
  width: 400px;
  height: 400px;
  margin-top: 50px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}

.box_register {
  /* display: flex; */
  width: 400px;
  margin-top: 100px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}

.cover {
  background-color: white;
  width: 70%;
  height: 50%;
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.55em rgb(156, 156, 156);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.title {
  margin-top: 0px;
  font-family: 'Roboto';
  font-size: 25px;
}

.label {
  display: block;
  font-size: larger;
  color: white;
  padding: 5px;
}

/* input {
  margin-top: 20px;
  border-width: 0.5px;
  border-color: rgb(241, 244, 245);
  background-color: #ffffff;
  width: 80%;
  border-radius: 4px;
  text-align: center;
  height: 20px;
} */

input {
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px #ccc;
  height: 50px;
  width: 80%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

input input[type='password'] {
  -webkit-text-security: disc;
  text-security: disc;
}

input:focus {
  outline-color: rgb(32, 177, 255);
}

label {
  text-align: left;
}

.button_primary {
  border: none;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #ff9933;
  color: white;
  height: 50px;
  width: 80%;
  padding: 10px;
  font-size: 16px;
}

.glossy-button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  text-shadow: 0px 1px 0px #268bd2;
  box-shadow: 0px 5px 0px #268bd2;
}

.glossy-button:hover {
  background-color: #268bd2;
  box-shadow: 0px 3px 0px #268bd2;
}

h2 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 30px;
  color: #808080;
}
