.containerTags {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  width: 100%;
}

.contentTags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
}

.eachTag {
  padding: 10px;
  background-color: #e0e0e0;
}

.tag1 {
  display: flex;
  flex-wrap: wrap;
  background-color: #e0e0e0;
  padding: 6px 5px;
  border-radius: 5px;
  margin: 5px;
}

.tagTitle {
  display: flex;
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  align-items: flex-start;
  border-bottom: 1px solid black;
}

.underline-title {
  border-bottom: 1px solid black;
  display: inline-block;
  padding-bottom: 5px;
}
