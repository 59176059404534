.containerCard {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.containerCardContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
}

.cardImg {
  width: 80%;
  height: 100px;
  object-fit: cover;
  border-radius: 7px;
}

.cardTitle {
  display: flex;
  font-family: Roboto;
  font-size: 15px;
  padding-top: 10px;
}

.cardDesc {
  display: flex;
  font-family: Roboto;
  font-size: 14px;
}

.cardReadMore {
  display: flex;
  font-family: Roboto;
  font-size: 14px;
}
.cardLikeAndCommentsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-family: Roboto;
  font-size: 14px;
  border-bottom: 1px solid black;
}
