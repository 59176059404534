.user-info {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo-text {
  font-family: 'Zeyada', cursive;
  font-size: 18px;
}

.logo-firstname1 {
  font-family: 'WindSong', cursive;
  font-size: 16px;
  font-weight: 500;
}

.logo-lastname {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
}

.logo-icon {
  padding-left: 6px;
  color: #009900;
  /* height: 20px; */
}
