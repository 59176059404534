.top {
  width: 100%;
  height: 80px;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  /* border-bottom: 1px solid #ccc; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: 'center';
  justify-content: center;
  align-content: center;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: '#444';
  cursor: pointer;
}
.topCenter {
  flex: 6;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.my-link {
  color: #000000;
  text-decoration: none;
  font-weight: 300;
}

.active {
  font-weight: bold;
  width: 100px;
  border-radius: 5px;
  background-color: #e0e0e0;
  /* border-bottom: 2px solid red; */
  padding: 2px;
}
.topSearchIcon {
  font-size: 18px;
  color: #666;
  cursor: pointer;
}

.topBarProfileImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.topBarRightText {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  align-self: center;
}

.topBarRightNameText {
  margin-left: 10px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  color: #a9a9a9;
  cursor: pointer;
  align-self: center;
}
