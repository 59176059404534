.containerSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-field {
  position: relative;
  display: inline-block;
}

.search-field input {
  padding-right: 30px; /* Adjust this value to make room for the icon */
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 60%;
  right: 10px; /* Adjust this value to position the icon */
  transform: translateY(-50%);
}

.searchTitle {
  display: flex;
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  align-items: flex-start;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
